import Topbar from "./components/topbar/Topbar";
import Homepage from "./pages/homepage/Homepage";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Bottombar from "./components/bottombar/Bottombar";
import Consult from "./pages/consult/Consult";
import Adres from "./pages/adres/Adres";
import Tips from "./pages/tips/Tips";
import Praktijk from "./pages/praktijk/Praktijk";
import Contact from "./pages/contact/Contact";
import {useEffect, useState} from "react";
import TopbarMobiel from "./components/topbar-mobiel/TopbarMobiel";
import ConsultMobiel from "./pages/consult-mobiel/Consult-mobiel";
import TipsMobiel from "./pages/tips-mobiel/tipsMobiel";

function App() {

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        };
    }, []);

    useEffect(()=>{
        document.title= "Vandaag Begin Ik";
    }, [])

    const isMobile = width <= 768;

    if (isMobile) {
        return (
            <Router>
                <TopbarMobiel/>
                <Switch>
                    <Route exact path="/">
                        <Homepage/>
                    </Route>
                    <Route exact path="/praktijk">
                        <Praktijk></Praktijk>
                    </Route>
                    <Route exact path="/consult">
                        <ConsultMobiel></ConsultMobiel>
                    </Route>
                    <Route exact path="/contact">
                        <Contact></Contact>
                    </Route>
                    <Route exact path="/adres">
                        <Adres></Adres>
                    </Route>
                    <Route exact path="/tips">
                        <TipsMobiel></TipsMobiel>
                    </Route>
                </Switch>
                <Bottombar></Bottombar>
            </Router>


        )

    } else {
        return (


            <Router>
                <Topbar/>
                <Switch>
                    <Route exact path="/">
                        <Homepage/>
                    </Route>
                    <Route exact path="/praktijk">
                        <Praktijk></Praktijk>
                    </Route>
                    <Route exact path="/consult">
                        <Consult></Consult>
                    </Route>
                    <Route exact path="/contact">
                        <Contact></Contact>
                    </Route>
                    <Route exact path="/adres">
                        <Adres></Adres>
                    </Route>
                    <Route exact path="/tips">
                        <Tips></Tips>
                    </Route>
                </Switch>
                <Bottombar></Bottombar>
            </Router>
        );
    }
}

export default App;
