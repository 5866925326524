import "./homepage.css";
import Praktijk from "../praktijk/Praktijk";
import {useEffect} from "react";

export default function Homepage() {

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    const handleContextMenu = (e) => {
        e.preventDefault();
    };

    const landingPages = {
        1: require("../../static/afbeeldingen/landing-pages/landing-page-januari-01.png"),
        2: require("../../static/afbeeldingen/landing-pages/landing-page-febrauri-01.png"),
        3: require("../../static/afbeeldingen/landing-pages/landing-page-maart-01.png"),
        4: require("../../static/afbeeldingen/landing-pages/landing-page-april-01.png"),
        5: require("../../static/afbeeldingen/landing-pages/landing-page-mei-01.png"),
        6: require("../../static/afbeeldingen/landing-pages/landing-page-juni-01.png"),
        7: require("../../static/afbeeldingen/landing-pages/landing-page-juli-01.png"),
        8: require("../../static/afbeeldingen/landing-pages/landing-page-augustus-01.png"),
        9: require("../../static/afbeeldingen/landing-pages/landing-page-september-01.png"),
        10: require("../../static/afbeeldingen/landing-pages/landing-page-oktober-01.png"),
        11: require("../../static/afbeeldingen/landing-pages/landing-page-november-01.png"),
        12: require("../../static/afbeeldingen/landing-pages/landing-page-december-01.png"),
    }

    const currentMonth = new Date().getMonth() + 1;

    const currentLandingPage = landingPages[currentMonth]

    return (
        <>
            <div className="home">
                <img
                    onContextMenu={handleContextMenu}
                    draggable={"false"}
                    className="hoofd-pagina-afbeelding"
                    src={currentLandingPage}

                    alt={`LandingPage ${currentMonth} Vandaag Begin Ik`  }
                />

            </div>
            <div className="horizontale-lijn-dik"/>
            <Praktijk></Praktijk>
        </>
    );
}
