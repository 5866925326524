import React, {useEffect} from "react";
import "./praktijk.css"

export default function Praktijk() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className="praktijk">
            <div className="container">
                <img className='afbeelding'
                     src={require("../../static/afbeeldingen/meloen-compressed.jpg")}
                     alt="Meloen Leefstijladvies"
                />
                <div>
                    <h1 className="titel_praktijk">
                        Een passend voedings – en leefstijladvies
                    </h1>
                    <h3 className="tekst">
                        Of het nu middels je vrienden, familie of social media is, we krijgen veel informatie tot ons om
                        aan
                        onze gezondheid te werken.
                    </h3>
                    <h3 className="tekst">
                        Maar soms vraag je je af of je wel het juiste pad bewandelt.
                    </h3>
                    <h3 className="tekst">
                        Bij <span className="andere-font">Vandaag-Begin-Ik</span> wordt gestreefd naar haalbare doelen
                        op
                        het gebied van voeding, beweging en ontspanning. Eenvoudig en passend in het dagelijks leven.
                    </h3>
                    <h3 className="tekst">
                        Genieten van het eten en energie halen uit activiteiten staan voorop.

                    </h3>
                </div>
            </div>
            <div className="horizontale-lijn"/>
            <div className="container">
                <img className='afbeelding'
                     src={require("../../static/afbeeldingen/qualizorg-compressed.jpg")}
                     alt="Qualizorg"
                />
                <div className="rechts">
                    <h2 className="titel_praktijk">
                        Qualizorg
                    </h2>
                    <h3 className="tekst">
                        Het is belangrijk een zo optimaal mogelijke zorg te kunnen verlenen.
                    </h3>
                    <h3 className="tekst">
                        Wat vinden de cliënten van de geboden ondersteuning en de toegevoegde waarde in hun kwaliteit
                        van leven?
                    </h3>
                    <h3 className="tekst">
                        Dat leest u hier binnenkort.

                    </h3>
                </div>


            </div>
            <div className="horizontale-lijn"/>

            <div className="container">
                <img className="afbeelding"
                     src={require("../../static/afbeeldingen/Profielfoto-01.png")}
                     alt="Diëtist Rita Wilschut">
                </img>
                <div className="rechts">
                    <h2 className="titel_praktijk">
                        In contact met Rita Wilschut
                    </h2>
                    <h3 className="tekst">
                        Nadat ik een groot aantal jaren les heb gegeven en in de farmacie heb gewerkt zijn we met onze 4
                        kinderen
                        verhuisd naar België en vervolgens naar Spanje.
                        Bij terugkomst in Nederland ben ik 8 jaar geleden gestart met een diëtistenpraktijk waarbij een
                        aanpassing van de leefstijl voorop is komen te staan.
                    </h3>
                    <h3 className="tekst">

                        Dagelijks zie ik mensen fitter worden, meer energie krijgen en stapjes maken in de richting van
                        een
                        betere balans en een gezondere leefstijl.
                        Belangrijk vind ik dat de ingeslagen route bij een cliënt aangepast kan worden als er zich situaties
                        voordoen waarbij dat nodig is.
                        Dat is een mooie uitdaging om de gestelde doelen te bereiken.
                    </h3>
                    <div className="tekst">
                        Welkom in mijn praktijk!
                    </div>
                </div>
            </div>
            <div className="horizontale-lijn"/>

            <div className="container">
                <img className="afbeelding"
                     src={require("../../static/afbeeldingen/chocolade-aarbei-compressed.jpg")}
                     alt="Chocolade Diëtisten Coöporatie Nederland (DCN)">
                </img>
                <div className="rechts">
                    <h2 className="titel_praktijk">
                        Mijn Aandachtsgebieden
                    </h2>
                    <h3 className="tekst">
                        Na de vierjarige HBO opleiding Voeding & Diëtetiek aan de Haagse Hogeschool in Den Haag heb ik
                        de lerarenopleiding in Delft gevolgd (de vakken gezondheidskunde en huishoudkunde). Voordat ik
                        mijn eigen praktijk startte (8 jaar geleden) heb ik de 3 jarige HBO opleiding tot vitaal coach
                        gevolgd en de post -HBO Diëtetiek aan de HVA te Amsterdam afgerond.
                    </h3>
                    <h3 className="tekst">

                        Om op de hoogte te blijven van de meest recente ontwikkelingen, richtlijnen en protocollen volg
                        ik ieder jaar diverse bij – en nascholingen en is er maandelijks casuïstiek bespreking in een
                        intervisiegroep met collega diëtisten.
                    </h3>
                    <h3 className="tekst">
                        Om kwaliteit te garanderen sta ik ingeschreven in het <span className="tekst-cursief"> Kwaliteitsregister Paramedici</span> en ben ik
                        lid van de <span className="tekst-cursief"> Diëtisten Coöperatie Nederland</span> (DCN).
                    </h3>
                    <div className="tekst">
                        Een aantal van de bij- en nascholingen die ik heb gevolgd:
                    </div>
                    <ul className="tekst">
                        <li>Motivational Interviewing</li>
                        <li>COPD – post HBO</li>
                        <li>Diabetes Mellitus</li>
                        <li>Cognitieve Gedragstherapie</li>
                        <li>Bravo Leefstijlfactoren en Leefstijl coaching</li>
                        <li>Kanker en de rol van Voeding en Leefstijl</li>
                        <li>Cardiovasculair Risico Management</li>
                    </ul>
                </div>
            </div>
        </div>

    )

}