import {Link, useLocation} from "react-router-dom";
import "./topbar.css";
import React, {useEffect} from "react";
import TextLinkBoven from "./TextLinkBoven";

export default function Topbar() {
    const location = useLocation();
    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 90) {
                document.querySelector('.top').classList.add('scrolled')
                document.querySelector('.logo').classList.add('scrolled')
            }
            if (window.pageYOffset < 50) {
                document.querySelector('.top').classList.remove('scrolled')
                document.querySelector('.logo').classList.remove('scrolled')
            }
        };
        window.addEventListener("scroll", handleScroll);
        return() => {
            window.removeEventListener("scroll", handleScroll);
        };

    }, []);

    return (
        <div className="top">
            <div className="green-bar">
                <img
                    className="logo"
                    src={require("../../static/afbeeldingen/Final-Logo-01.png")}
                    alt="logo"
                />


                <div className="topTitle">
                    <Link className="link" to="/">
                        <TextLinkBoven></TextLinkBoven>
                    </Link>
                </div>

                <div className="topCenter">
                    <ul className="topList">
                        <li className="topListItem">
                            <Link className={location.pathname === "/praktijk" ? 'actief' : 'link'} to="/praktijk">
                                Praktijk
                            </Link>
                        </li>
                        <li className="topListItem">
                            <Link className={location.pathname === "/consult" ? 'actief' : 'link'}  to="/consult">
                                Consult
                            </Link>
                        </li>
                        <li className="topListItem">
                            <Link className={location.pathname === "/tips" ? 'actief' : 'link'}  to="/tips">
                                Tips
                            </Link>
                        </li>

                        <li className="topListItem">
                            <Link className={location.pathname === "/adres" ? 'actief' : 'link'}  to="/adres">
                                Adres
                            </Link>
                        </li>
                        <li className="topListItem">
                            <Link className={location.pathname === "/contact" ? 'actief' : 'link'}  to="/contact">
                                Contact
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
