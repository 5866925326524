import React, {useEffect} from "react";
import {GrLocation} from "react-icons/gr"
import "./adres.css"


export default function Adres() {

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    return (
        <div className="container-adres">
            <h1 className="tekst-adres">
                U bent van harte uitgenodigd op een van onderstaande locaties in Breda nadat u een afspraak heeft
                gemaakt.
            </h1>
            <h2 className="tekst-adres-cursief">
                Indien medisch noodzakelijk worden er op dinsdagmiddag en vrijdag huisbezoeken afgelegd.
            </h2>
            <div className="adres">
                <div className="column-adres">
                    <h3 className="tekst-column-adres">
                        <GrLocation/> Bijster 30
                    </h3>
                </div>
                <div className="column-adres">
                    <h3 className="tekst-column-adres">
                        Club Pellikaan
                    </h3>
                </div>
            </div>
            <div className="adres">
                <div className="column-adres">

                    <h3 className="tekst-column-adres">
                        <GrLocation/> Wilderen 2
                    </h3>
                </div>
                <div className="column-adres">

                    <h3 className="tekst-column-adres">
                        Para Medisch Centrum Heusdenhout
                    </h3>
                </div>
            </div>
            <div className="adres">
                <div className="column-adres">

                    <h3 className="tekst-column-adres">
                        <GrLocation/> Draaiboom 119
                    </h3>
                </div>
                <div className="column-adres">

                    <h3 className="tekst-column-adres">
                        dr. Schueler
                    </h3>
                </div>
            </div>
            <div className="adres">
                <div className="column-adres">

                    <h3 className="tekst-column-adres">
                        <GrLocation/> Pastoor Pottersplein 51
                    </h3>
                </div>
                <div className="column-adres">

                    <h3 className="tekst-column-adres">
                        dr. Muskee
                    </h3>
                </div>
            </div>

        </div>

    )


}