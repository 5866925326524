import React, {useState, useRef, useEffect} from "react";
import "./contact.css"
import emailjs from '@emailjs/browser';

export default function Contact() {

    const form = useRef();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const [clicked, setClicked] = useState(false);

    const handleVerzenden = (e) => {
        e.preventDefault();
        setClicked(true);

        emailjs.sendForm('service_k5uysut', 'template_n4ijboz', form.current, 'K26pZOU-pqgwRUtgF')
            .then((result) => {
                console.log(result.text);
            }).catch((error) => {
                console.log(error);
            }
        );
    }


    return (
        <div>
            <h1 className="titel-contact">Contact</h1>
            <div className="container-contact">
                <div className="column-contact">
                    <form ref={form} onSubmit={handleVerzenden} className="form">
                        <div className="namen">
                            <div className="input-container">
                                <label htmlFor="voorNaam"
                                       className="label-contact"
                                >Voornaam:
                                    <div className="verplicht">*</div>
                                </label>
                                <input
                                    className="input-namen"
                                    type="text"
                                    id="voorNaam"
                                    name="voorNaam"
                                    required
                                    title="Voer aub uw voornaam in."
                                />
                            </div>
                            <div className="input-container">
                                <label htmlFor="achterNaam"
                                       className="label-contact">Achternaam:
                                    <div className="verplicht">*</div>
                                </label>
                                <input
                                    className="input-namen"
                                    type="text"
                                    id="achterNaam"
                                    name="achterNaam"
                                    required
                                    title="Voer aub uw achternaam in."

                                />
                            </div>
                        </div>
                        <div className="input-container">
                            <label htmlFor="telefoonnummer"
                                   className="label-contact">
                                Telefoonnummer:
                                <div className="verplicht">*</div>
                            </label>
                            <input
                                className="input-contact"
                                type="text"
                                id="telefoonnummer"
                                name="telefoonnummer"
                                required
                                title="Voer aub uw telefoonnummer in."

                            />
                        </div>
                        <div className="input-container">
                            <label htmlFor="email"
                                   className="label-contact">
                                E-mail:</label>
                            <input
                                className="input-contact"
                                type="text"
                                id="email"
                                name="email"
                                title="Voer aub uw e-mail in."
                            />
                        </div>
                        <div className="input-container">
                            <label htmlFor="bericht"
                                   className="label-contact">
                                Bericht:
                                <div className="verplicht">*</div>
                            </label>
                            <textarea
                                className="input-bericht"
                                id="bericht"
                                name="bericht"
                                required
                                title="Voer aub uw bericht in."

                            />
                        </div>
                        <div className="knop-container">
                            <button type="submit"
                                    className="knop"
                                    disabled={clicked}
                            >{clicked ? 'Verzonden' : 'Versturen'}
                            </button>
                            <div className="info">
                                <div className="verplicht">*</div>
                                Deze velden zijn verplicht
                            </div>
                        </div>
                    </form>
                </div>
                <div className="column-contact">
                    <div className="form">
                        <h2 className="column-tekst">
                            Het streven is uw bericht binnen 2 werkdagen te beantwoorden.
                            Mocht u dan niets hebben vernomen, kijk dan in de spam box of bij de "ongewenste berichten".
                            Beantwoorde berichten kunnen ook daar terecht komen.
                        </h2>
                        <div className="whitespace"></div>
                        <h2 className="column-tekst">
                            Voor vragen kunt u vrijblijvend contact opnemen via
                        </h2>
                        <div className="small-whitespace"></div>
                        <div className="column-tekst">
                            <span className="bolt"> 06 307 559 79</span>
                            <span className="of"> of </span>
                            <span className="bolt">rita-wilschut@vandaag-begin-ik.nl</span>
                        </div>

                        <div className="whitespace"></div>
                        <h2 className="column-tekst">
                            Graag tot ziens op het spreekuur!
                            <div className="whitespace"></div>

                            Vriendelijke groet,
                        </h2>
                        <div className="small-whitespace"></div>

                        <h2 className="column-tekst">
                            Rita Wilschut
                        </h2>
                        <div className="new-line"></div>
                        <h2 className="column-tekst">
                            Vandaag Begin Ik
                        </h2>

                        <div className="whitespace"></div>
                        <h2 className="column-tekst">
                            Door het contactformulier in te vullen gaat u akkoord met de privacyverklaring van Vandaag
                            Begin Ik.
                        </h2>
                        <div className="small-whitespace"></div>
                        <h2 className="column-tekst">
                            Lees
                            <a href={'../../Privacyverklaring.pdf'} target="_blank" rel="noopener noreferrer"
                               className="button">hier</a>
                            de privacyverklaring.
                        </h2>
                    </div>
                </div>
            </div>
        </div>

    );

}