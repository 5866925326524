import React, {useEffect} from 'react';
import "./tips.css"

export default function Tips() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div>
            <h1 className="tips-titel-hoofd">Tips</h1>
            <div className="container-tips">

                <div className="left-column">
                    <h2 className="tips-titel">
                        Breda Wandelt
                    </h2>
                    <div className="whitespace"/>
                    <h3 className="column-tekst-tips">
                        Door 2 enthousiaste dames is er een mooi initiatief gestart : “BredaWandelt”. Hoe leuk is het
                        met
                        anderen op stap te gaan om de prachtige plekjes in en rondom Breda te ontdekken, in beweging te
                        zijn
                        en met onbekenden in contact te komen.
                        Naast de wandelingen in de natuur zijn er ook routes waar je een stukje cultuur opsnuift en de
                        enthousiaste begeleiders van alles vertellen over bijzondere plekjes die aangedaan worden.
                    </h3>
                    <div className="whitespace"/>
                    <h3 className="column-tekst-tips">
                        En nog leuker… ook is er de mogelijkheid je aan te melden als lid en op de hoogte te blijven van
                        de
                        mooie tochten die gemaakt kunnen worden. Meedoen aan een “proefwandeling“ kan ook.
                        De wandelingen zijn geschikt voor beginners en gevorderden en zijn 5 – 8 km lang. Er is ook
                        altijd
                        tijd voor een drankje en / of versnapering.
                        Kijk eens op:
                        <a href="https://www.breda-wandelt.nl" target="_blank" rel="noreferrer">
                            <span className="link-tekst">
                                www.breda-wandelt.nl
                            </span>
                        </a>

                    </h3>
                </div>
                <div className="right-column">
                    <img
                        src={require('../../static/afbeeldingen/breda_wandelt.png')}
                        alt="Breda Wandelt"
                        draggable={"false"}


                    />
                </div>

            </div>
            <div className="whitespace"/>
            <div className="horizontale-lijn"/>

            <div className="container-tips">
                <div className="left-column">
                    <h2 className="tips-titel">
                        hart for her
                    </h2>
                    <div className="whitespace"/>
                    <h3 className="column-tekst-tips">
                        Voor iedere vrouw die zich fit en zeker wil voelen
                    </h3>
                    <div className="whitespace"/>
                    <h3 className="column-tekst-tips">
                        hart for her is een trainingsprogramma speciaal voor vrouwen en erop gericht om jou energieker,
                        fit en zekerder te laten voelen. In 30 minuten doorloop je een circuit op eigen niveau, zonder
                        spierpijn of blessure en we begeleiden je hier graag bij.
                    </h3>
                    <div className="whitespace"/>
                    <h3 className="column-tekst-tips">
                        Leden omschrijven hart for her als een plek waar je echt jezelf kunt zijn. Investeer in jouw
                        gezondheid, voor nu en later.

                    </h3>
                    <h3 className="column-tekst-tips">
                        Kijk eens op:
                        <a href="https://www.hartforher.nl/vestigingen/vrouwen-sport-fitness-afvallen-breda"
                           target="_blank" rel="noreferrer">
                            <span className="link-tekst">
                                www.hartforher.nl
                            </span>
                        </a>

                    </h3>
                </div>
                <div className="right-column">
                    <img
                        src={require('../../static/afbeeldingen/hartforher.png')}
                        alt="Breda Wandelt"
                        draggable={"false"}


                    />
                </div>
            </div>

            <div className="whitespace"/>
            <div className="horizontale-lijn"/>

            <div className="container-tips">
                <div className="left-column">
                    <h2 className="tips-titel">
                        Praktijk voor Integrale Geneeskunde West-Brabant
                    </h2>
                    <div className="whitespace"/>
                    <h3 className="column-tekst-tips">
                        Bij Mesologie staat het begrijpen van uw klachten en de achterliggende oorzaak centraal. Een
                        mesoloog behandelt een groot scala aan begrepen en onbegrepen gezondheidsklachten voor iedereen,
                        van jong tot oud, en begrijpt de oorzaak van uw klacht door verschillende geneeswijzen te
                        combineren.
                    </h3>
                    <div className="whitespace"/>
                    <h3 className="column-tekst-tips">
                        Wilt u een effectief gezondheidsplan specifiek voor uw klacht waarbij de zelfgenezende kracht
                        van het menselijk lichaam gerespecteerd wordt, kies dan voor Mesologie.
                    </h3>
                    <h3 className="column-tekst-tips">
                        Kijk eens op:
                        <a href="https://www.mesoloog.info/nl-nl/"
                           target="_blank" rel="noreferrer">
                            <span className="link-tekst">
                                www.mesoloog.nl
                            </span>
                        </a>

                    </h3>
                </div>
                <div className="right-column">
                    <img
                        src={require('../../static/afbeeldingen/praktijk-geneeskunde.png')}
                        alt="Praktijk Geneeskunde"
                        draggable={"false"}


                    />
                </div>
            </div>
        </div>
    )

}