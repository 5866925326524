import "./consult-mobiel.css"
import React, {useEffect} from "react";

export default function ConsultMobiel() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const handleContextMenu = (e) => {
        e.preventDefault();
    };


    return (
        <>
            <div className="consult-container-mobiel">

                <h1 className="mobiel-titel-consult">
                    Leefstijl
                </h1>
                <div className="whitespace"></div>

                <h2 className="consult-titel-mobiel">
                    Beweging
                </h2>
                <h3 className="consult-tekst-mobiel">
                    Regelmatig bewegen is gezond en voor veel mensen ook leuk om samen te doen. Als je veel
                    beweegt, raken je spieren, longen, hart en bloedvaten eraan gewend om te werken. Je voelt je
                    lichamelijk en psychisch fitter en je krijgt er energie van.
                    Lekker bewegen heeft veel voordelen: het helpt beter te ontspannen en je hebt minder last
                    van stress, het werkt goed bij somberheid, je slaapt beter en bent minder moe.
                    We kijken samen naar activiteiten die leuk zijn, vol te houden zijn en in te passen zijn in
                    het dagelijks leven.
                    Op deze manier draagt beweging bij aan een gezonde leefstijl.

                </h3>
                <img
                    onContextMenu={handleContextMenu}
                    draggable={"false"}
                    className="column-afbeelding"
                    src={require("../../static/afbeeldingen/beweging-compressed.jpg")}
                    alt={"beweging afvallen"}
                />
                <h2 className="consult-titel-mobiel">
                    Voeding
                </h2>
                <h3 className="consult-tekst-mobiel">
                    Om goed voor jezelf te zorgen, is gezonde voeding belangrijk.
                    Er zijn veel ziektes waarbij met de juiste voedingsaanpassingen symptomen verminderen of
                    zelfs verdwijnen. Denk bijvoorbeeld aan Diabetes Mellitus, COPD, ondervoeding, buikklachten,
                    (lactose) – intolerantie, hart- en vaatziekten, hypertensie, migraine.
                    Wellicht staat jouw ziektebeeld er niet bij.
                    Neem dan gerust vrijblijvend contact op voor meer informatie.

                </h3>
                <img
                    onContextMenu={handleContextMenu}
                    draggable={"false"}
                    className="column-afbeelding"
                    src={require("../../static/afbeeldingen/voeding-compressed.jpg")}
                    alt={"voeding gezond eten"}
                />

                <h2 className="consult-titel-mobiel">
                    Ontspanning
                </h2>
                <h3 className="consult-tekst-mobiel">
                    Iedereen heeft wel eens stress. Het hoort bij het leven.
                    Maar als je veel stress hebt kun je soms niet meer ontspannen. Je kunt dan klachten krijgen.
                    Door op tijd en genoeg te ontspannen kun je dat voorkomen en voel je je beter.
                    Door te ontspannen werkt je lichaam weer beter na stress.
                    Als je ontspant, adem je rustiger, klopt je hart langzamer en wordt je bloeddruk lager.
                    We kijken naar verschillende manieren om te ontspannen zodat ze een positieve bijdrage
                    leveren aan een gezondere leefstijl.
                </h3>
                <img
                    onContextMenu={handleContextMenu}
                    draggable={"false"}
                    className="column-afbeelding"
                    src={require("../../static/afbeeldingen/ontspanning-compressed.jpg")}
                    alt={"ontspanning leefstijl"}
                />

                <h2 className="consult-titel-mobiel">
                    Tarieven
                </h2>
                <h3 className="consult-tekst-mobiel">
                    De tarieven voor dieetadvisering variëren per zorgverzekeraar. Ze liggen gemiddeld tussen de
                    15,75 euro en 18,25 euro per kwartier. Hieronder een indicatie van de bedragen.
                </h3>
                <div className="whitespace"></div>

                <h3 className="consult-tekst-mobiel">
                    <span className="consult-tekst-mobiel-bold"> Intakeconsult </span>inclusief uitgebreid individueel
                    advies:
                </h3>
                <div className="whitespace"></div>
                <h3 className="consult-tekst-mobiel">
                    €94,50 – €109,50 ( afhankelijk van uw zorgverzekeraar).
                    Een intake duurt doorgaans een uur. Er wordt directe tijd van 60 minuten gerekend en
                    indirecte tijd van 30 minuten voor het uitwerken van het individueel dieetadvies

                </h3>
                <div className="whitespace"></div>

                <h3 className="consult-tekst-mobiel">
                    <span className="consult-tekst-mobiel-bold">Vervolgconsult</span> van 15 minuten:

                </h3>
                <div className="whitespace"></div>

                <h3 className="consult-tekst-mobiel">
                    €15,75 – €18,25 ( afhankelijk van uw zorgverzekeraar)

                </h3>
                <div className="whitespace"></div>

                <h3 className="consult-tekst-mobiel">
                    <span className="consult-tekst-mobiel-bold">Vervolgconsult</span> van 30 minuten:
                </h3>
                <div className="whitespace"></div>

                <h3 className="consult-tekst-mobiel">
                    €31,50 - €36,50 ( afhankelijk van uw zorgverzekeraar)
                </h3>
                <div className="whitespace"></div>
                <h2 className="consult-titel-mobiel">
                    Vergoeding en Voorwaarden
                </h2>

                <h3 className="consult-tekst-mobiel">
                    In 2023 wordt dieetadvisering met een medische indicatie vergoed voor 3 uur vanuit de
                    basisverzekering. Wel gebruikt u eerst uw eigen risico.
                    Leefstijl- en diëtistenpraktijk Vandaag-Begin-Ik heeft contracten met alle zorgverzekeraars
                    zodat er rechtstreeks kan worden gedeclareerd.
                    Bij een eventuele aanvullende verzekering wordt de dieetadvisering vaak aangevuld met meer
                    uren. Kijk hiervoor in de polis van uw verzekering. De kosten uit de aanvullende verzekering
                    worden niet ingehouden op het eigen risico.

                </h3>
                <div className="whitespace"></div>

                <h3 className="consult-tekst-mobiel">
                    Afspraken kunnen tot 24 uur voor het consult kosteloos worden geannuleerd. De kosten zullen
                    voor eigen rekening zijn indien de afspraak niet tijdig wordt geannuleerd.
                </h3>
                <div className="whitespace"></div>
                <h3 className="consult-tekst-mobiel">
                    De overige voorwaarden van Leefstijl- en diëtistenpraktijk Vandaag-Begin-Ik zijn te
                    downloaden of op te vragen bij de diëtist:

                    <a href={'../../AlgemeneVoorwaarden.pdf'} target="_blank" rel="noopener noreferrer"
                       className="button">klik hier</a>

                </h3>

                <div className="whitespace"></div>
                <h3 className="consult-tekst-mobiel">
                    Voor de privacyverklaring:
                    <a href={'../../Privacyverklaring.pdf'} target="_blank" rel="noopener noreferrer"
                       className="button">klik hier</a>
                </h3>
            </div>


        </>
    )

}