import {Link} from "react-router-dom";
import "./topbarMobiel.css";
import React, {useState} from "react";

export default function TopbarMobiel() {

    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    const handleSluiten = () => setClick(false);

    return (

        <>
            <nav className='topbar-mobiel'>
                <div className='topbar-mobiel-container'>
                    <Link to='/' className='topbar-mobiel-logo' onClick={handleSluiten}>
                        <img
                            className="topbar-mobiel-logo"
                            src={require("../../static/afbeeldingen/Final-Logo-01.png")}
                            alt="logo"
                        />
                    </Link>
                    <div className="topbar-mobiel-titel">
                        Leefstijl- & Diëtistenpraktijk Rita Wilschut
                    </div>
                </div>
                <div className="topbar-mobiel-menu-container">
                    <div className="menu-icon" onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'}/>
                    </div>
                </div>
                <ul className={click ? 'topbar-menu active' : 'topbar-menu'}>
                    <li className="topbar-item">
                        <Link to="/praktijk" className="topbar-links" onClick={handleSluiten}>
                            Praktijk
                        </Link>
                    </li>
                    <li className="topbar-item">
                        <Link to="/consult" className="topbar-links" onClick={handleSluiten}>
                            Consult
                        </Link>
                    </li>
                    <li className="topbar-item">
                        <Link to="/tips" className="topbar-links" onClick={handleSluiten}>
                            Tips
                        </Link>
                    </li>
                    <li className="topbar-item">
                        <Link to="/adres" className="topbar-links" onClick={handleSluiten}>
                            Adres
                        </Link>
                    </li>
                    <li className="topbar-item">
                        <Link to="/contact" className="topbar-links" onClick={handleSluiten}>
                            Contact
                        </Link>
                    </li>
                </ul>
            </nav>
        </>
    );
}
