import "./textlinksboven.css"
import React from "react";

export default function TextLinkBoven() {

    return (
        <div className="text">
            <h1 className= "text">
                Leefstijl- & Diëtistenpraktijk
            </h1>
            <h2 className="text">
                Rita Wilschut
            </h2>
        </div>
    )

}