import "./bottombar.css"

export default function Bottombar() {


    return (
        <div className="bottom">
            <div className="bottomCenter">
                <ul className="bottomList">
                    <div className="bottomListItem" key={1}>vandaag-begin-ik.nl</div>

                </ul>
            </div>
        </div>
    )

}